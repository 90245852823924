import React from 'react';
import SimpleLevel from '../../components/simpleLevel/SimpleLevel';
import { Link } from 'react-router-dom';

function Level3() {
  let successMessage = <>
    <h1> Wytrwałość popłaca </h1>
    <p> ... </p>
    <Link to={"/game/level4"} className='simpleButton'> Let's rock level 4! </Link>
  </>

    return (
    <SimpleLevel 
      title = "Poziom 3"
      subTitle = "Lekko ziemiony poziom..."
      description = {<p> Więcej niż jedno zwierze to... </p>}
      levelPassword = "lama"
      successMessage = {successMessage}
    />
  );
}

export default Level3;
