import React from 'react';
import SimpleLevel from '../../components/simpleLevel/SimpleLevel';
import { Link } from 'react-router-dom';

function Level1() {
  let successMessage = <>
      <h1> Brawo Ty! Rozgrzewka za Tobą! </h1>
      <p> Teraz troszkę podniesiemy poziom trudności. Gotowa na poziom 2? </p>
      <Link to={"/game/level2"} className='simpleButton' > Przejdź do poziomu 2 </Link>
    </>
  

  return (
    <SimpleLevel 
      title = "Poziom 1"
      subTitle = "Palindrom"
      description = "Tytuł opisuje sam siebie"
      levelPassword = "187781"
      successMessage = {successMessage}
    />
  );
}

export default Level1;
