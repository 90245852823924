import React from 'react';
import SimpleLevel from '../../components/simpleLevel/SimpleLevel';
import { Link } from 'react-router-dom';

function Level2() {
  let successMessage = <>
    <h1> Idziesz jak burza! </h1>
    <p> Poziom 3 będzie troszkę inny... </p>
    <Link to={"/game/level3"} className='simpleButton' > Do poziom 3 </Link>
  </>

  let description = <>
    <p style={{textDecoration: "underline"}}>52.43647658698435, 16.618994295962207</p>
    <p>Tak, to cała zagadka</p>
  </>
  

  return (
    <SimpleLevel 
      title = "Poziom 2"
      subTitle = "Pewnego razu"
      description = {description}
      levelPassword = "Jankowice"
      successMessage = {successMessage}
    />
  );
}

export default Level2;
