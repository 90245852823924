import React from 'react';
import { Link } from 'react-router-dom';
import NumberLevel from '../../components/numberLevel/NumberLevel';
import HintImage from '../../assets/level4.png';

function Level5() {
  let successMessage = <>
    <h1> To nie było aż takie łatwe, brawo Ty! </h1>
    <Link to={"/game/level6"} className='simpleButton'> Następny poziom </Link>
  </>

    return (
    <NumberLevel 
      title = "Poziom 5"
      subTitle = "The answer to life the universe and everything"
      description = {<p> Zadanie numeryczne... <img src={HintImage} alt="Hint" width={'100%'}/></p>}
      levelPassword = "042"
      successMessage = {successMessage}
    />
  );
}

export default Level5;
