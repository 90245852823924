import React, { useState } from 'react';
import './numberform.css';

function NumberForm({ levelPassword, levelPassed, setLevelPassed, successMessage }) {
    const [password, setPassword] = useState('');
    const [lastTriedPassword, setLastTriedPassword] = useState('');
  
    const handlePasswordSubmit = (e) => {
      e.preventDefault();
      const lowercasePassword = password.toLowerCase();
      const lowercaseLevelPassword = levelPassword.toLowerCase();
  
      // Check if the password is correct (case-insensitive)
      if (lowercasePassword === lowercaseLevelPassword) {
        setLevelPassed(true);
      } else {
        setLastTriedPassword(password);
      }
    };
  
    const handleChange = (index, value) => {
      const newPassword = password.split('');
      newPassword[index] = value;
      setPassword(newPassword.join(''));
    };
  
    return (
      <div className="content">
        {levelPassed ? (
          <>
            {successMessage}
            <div className='correct'>
              Zagadka rozwiązana!
            </div>
          </>
        ) : (
          <form onSubmit={handlePasswordSubmit} className='form-container'>
            <div className='input-row'>
              {levelPassword.split('').map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={password[index] || ''}
                  onChange={(e) => handleChange(index, e.target.value)}
                  className={lastTriedPassword !== '' ? 'incorrect' : ''}
                />
              ))}
            </div>
            <button type="submit" className='simpleButton'>Let's go!</button>
            {lastTriedPassword !== '' ? <div className='incorrect'>Niestety, {lastTriedPassword} nie jest prawidłowym hasłem </div> : null}
          </form>
        )}
      </div>
    );
  }
  
  export default NumberForm;

