import React, { useState, useEffect } from 'react';
import '../styles/malinka.css'; // Import CSS file for styling
import videoSrc from '../assets/mati.mp4'; // Import the video file
import videoSrc2 from '../assets/mati2.mp4'; // Import the video file

function Malinka() {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const heartsContainer = document.querySelector('.hearts-container');
    const numberOfHearts = 10;

    for (let i = 0; i < numberOfHearts; i++) {
      const heart = document.createElement('div');
      heart.classList.add('heart');
      heart.style.left = `${Math.random() * 100}vw`; // Random horizontal position
      heart.style.animationDuration = `${Math.random() * 2 + 3}s`; // Random animation duration between 3s and 5s
      heartsContainer.appendChild(heart);
    }
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    // Check if the password is correct
    if (password === 'kochammalinke123') { // Replace 'yourpassword' with the actual password
      setIsAuthenticated(true);
    }
  };

  // Get the current date
  const currentDate = new Date();
  // Define the date for comparison (8th of March 2024)
  const targetDate = new Date('2024-02-26T19:26:00');

  // Check if the current date is after or equal to the target date
  const isTimeToShowContent = currentDate >= targetDate;

  return (
    <div className="container">
      <div className="content">
        {isTimeToShowContent ? (
          isAuthenticated ? (
            // Content to show after 8th of March 2024 if authenticated
            <div>
              <h1>❤️</h1>
              <p>Obejrzyj proszę te dwa filmy</p>
              <p>Najpierw ten pierwszy (po lewej, albo na górze, jego pozycja zależy do wielkości wyświetlacza)</p>

              <video height={300} controls>
                <source src={videoSrc2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <video height={300} controls>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            // Password form if not authenticated
            <form onSubmit={handlePasswordSubmit} className='form-container'>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
              <button type="submit">Submit</button>
            </form>
          )
        ) : (
          // Content to show before 8th of March 2024
          <div>
            <h1>It's not time yet.</h1>
          </div>
        )}
      </div>
      <div className="hearts-container"></div>
    </div>
  );
}

export default Malinka;
