import React from 'react';
import { Link } from 'react-router-dom';
import SimpleLevel from '../../components/simpleLevel/SimpleLevel';

function Level4() {
  let successMessage = <>
    <h1> So beautiful! </h1>
    <Link to={"/game/level5"} className='simpleButton'> Czy jest już poziom 5? </Link>
  </>

    return (
    <SimpleLevel 
      title = "Poziom 4"
      subTitle = "I need you"
      description = {<p> Poproszę ładne selfie zrobione teraz, a nie wcześniej, to poznasz hasło ;*</p>}
      levelPassword = "aezakmi"
      successMessage = {successMessage}
    />
  );
}

export default Level4;
