import React, { useState } from 'react';
import './passwordform.css';

function PasswordForm({levelPassword, levelPassed, setLevelPassed, successMessage}) {
  const [password, setPassword] = useState('');
  const [lastTriedPassword, setLastTriedPassword] = useState('');

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const lowercasePassword = password.toLowerCase();
    const lowercaseLevelPassword = levelPassword.toLowerCase();

    // Check if the password is correct (case-insensitive)
    if (lowercasePassword === lowercaseLevelPassword) {
        setLevelPassed(true);
    } else {
        setLastTriedPassword(password);
    }
  };

  return (
    <div className="content">
        {
            levelPassed ? (
                <>
                {successMessage}
                <div className='correct'>
                    Zagadka rozwiązana!
                </div>
                </>    
            ) : (
                <form onSubmit={handlePasswordSubmit} className='form-container'>
                <input
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Wprowadź rozwiązanie..."
                />
                <button type="submit">Let's go!</button>
                
                { lastTriedPassword !== '' ? <div className='incorrect'>Niestety, {lastTriedPassword} nie jest prawidłowym hasłem </div> : null}

                </form>
            )
        }
    </div>
  );
}

export default PasswordForm;
