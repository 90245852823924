import React from 'react';

function Game() {
  return (
    <div className="container">
      Game
    </div>
  );
}

export default Game;
