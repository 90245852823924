import React, { useState } from 'react';
import PasswordForm from '../passwordForm/PasswordForm';
import './simpleLevel.css';

function SimpleLevel({title, subTitle, description, successMessage, levelPassword}) {
  const [levelPassed, setLevelPassed] = useState(false);

  return (
    <div className="container">
        { title ? <h1> {title} </h1> : null}
        { subTitle ? <h2> {subTitle} </h2> : null}
        { description ? <div className='description'> {description} </div> : null}
        <PasswordForm levelPassword={levelPassword} levelPassed={levelPassed} setLevelPassed={setLevelPassed} successMessage={successMessage}/>
    </div>
  );
}

export default SimpleLevel;
