import React, { useState } from 'react';
import NumberForm from '../numberForm/NumberForm';
import './numberLevel.css';

function NumberLevel({title, subTitle, description, successMessage, levelPassword}) {
  const [levelPassed, setLevelPassed] = useState(false);

  return (
    <div className="container">
        { title ? <h1> {title} </h1> : null}
        { subTitle ? <h2> {subTitle} </h2> : null}
        { description ? <div className='description'> {description} </div> : null}
        <NumberForm levelPassword={levelPassword} levelPassed={levelPassed} setLevelPassed={setLevelPassed} successMessage={successMessage}/>
    </div>
  );
}

export default NumberLevel;
